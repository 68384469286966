import { WebsiteId } from '@/env';

interface ISettings {
  domains: string[];
  websiteId: WebsiteId;
  localeCodeNameMap: string;
  urlLegalEntitiesLk: string;
}
// TODO: Как добавиться Азербайджанский нужно добавить для него urlLegalEntitiesLk: https://lk.cdek-az.com/
const domainSettings: ISettings[] = [
  {
    domains: ['www.cdek.kz', 'cdek.kz', 'website-kz.cdek.local'],
    websiteId: 'kz',
    localeCodeNameMap: 'ru:Русский;kz:Қазақ',
    urlLegalEntitiesLk: 'https://lk.cdek.kz/user/login',
  },
  {
    domains: ['www.cdek.by', 'cdek.by', 'website-by.cdek.local'],
    websiteId: 'by',
    localeCodeNameMap: 'ru:Русский;',
    urlLegalEntitiesLk: 'https://lk.cdek.by/user/login',
  },
  {
    domains: ['www.cdek-am.com', 'cdek-am.com', 'website-am.cdek.local'],
    websiteId: 'am',
    localeCodeNameMap: 'ru:Русский;am:հայերեն',
    urlLegalEntitiesLk: 'https://lk.cdek-am.com/user/login',
  },
  {
    domains: ['www.cdek.kg', 'cdek.kg', 'website-kg.cdek.local'],
    websiteId: 'kg',
    localeCodeNameMap: 'ru:Русский;kg:Киргизский',
    urlLegalEntitiesLk: 'https://lk.cdek.kg/user/login',
  },
  {
    domains: ['www.cdek.ge', 'cdek.ge', 'new.cdek.ge', 'website-ge.cdek.local'],
    websiteId: 'ge',
    localeCodeNameMap: 'ru:Русский;ge:Грузинский',
    urlLegalEntitiesLk: 'https://lk.cdek.ge/user/login',
  },
  {
    domains: ['www.cdek-express.uz', 'cdek-express.uz', 'website-uz.cdek.local'],
    websiteId: 'uz',
    localeCodeNameMap: 'ru:Русский;uz:Узбекский',
    urlLegalEntitiesLk: 'https://lk.cdek-express.uz/user/login',
  },
  {
    domains: ['website.cdek.local'],
    websiteId: 'ru',
    localeCodeNameMap: 'ru:Русский;kz:Қазақ;am:հայերեն',
    urlLegalEntitiesLk: 'https://lk.cdek.ru/user/login',
  },
];

export function getSettings(): ISettings | undefined {
  const hostname = window.location.hostname;
  return domainSettings.find((item) => item.domains.includes(hostname));
}

export function getWebsiteId(): WebsiteId {
  const envWebsiteId = import.meta.env.VITE_WEBSITE_ID;

  if (envWebsiteId) {
    return envWebsiteId;
  }

  const settings = getSettings();

  return settings?.websiteId || 'ru';
}

export function getLocaleCodeNameMap(): string {
  const envLocaleMap = import.meta.env.VITE_LOCALE_CODE_NAME_MAP;

  if (envLocaleMap) {
    return envLocaleMap;
  }

  const settings = getSettings();

  return settings?.localeCodeNameMap || 'ru:Русский';
}

export function getYandexMetrikaId(): string {
  const envWebsiteId = import.meta.env.VITE_WEBSITE_ID;

  if (envWebsiteId) {
    // Когда в конфигах указан WEBSITE_ID значит метрику берем из конфигов, не думая
    // Это особенно касается сайта
    return import.meta.env.VITE_YANDEX_METRIKA_ID;
  }

  // Для ЛК получаем данные в зависимости от домена
  const websiteId = getWebsiteId();

  switch (websiteId) {
    case 'kz':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_KZ;
    case 'by':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_BY;
    case 'am':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_AM;
    case 'kg':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_KG;
    case 'uz':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_UZ;
    case 'ge':
      return import.meta.env.VITE_YANDEX_METRIKA_ID_GE;
    default:
      return import.meta.env.VITE_YANDEX_METRIKA_ID;
  }
}
