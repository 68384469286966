import { getYandexMetrikaId } from '@/utils/international.utils';

const metrikaId = getYandexMetrikaId();

type Parameters = {
  [key: string]: string | number | boolean | null;
};

export function reachGoal(event: string, params?: Parameters) {
  window.ym && event && window.ym(metrikaId, 'reachGoal', event, params);
}

export function sendParams(parameters: string | Parameters): void {
  window.ym && window.ym(metrikaId, 'params', parameters);
}

export function sendUserParams(parameters: string | Parameters): void {
  window.ym && window.ym(metrikaId, 'userParams', parameters);
}

interface IDLProduct {
  name: string;
  category: string;
  quantity: number;
  price: number;
}

interface IDLActionType {
  actionField: {
    id: string;
  };
  products: IDLProduct[];
}

interface IDataLayer {
  ecommerce: {
    currencyCode: string;
    purchase: IDLActionType;
  };
}

export function pushDataLayer(data: IDataLayer) {
  window.dataLayer && window.dataLayer.push(data);
}
